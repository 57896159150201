import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import { navigate } from 'gatsby';
import 'antd/dist/antd.css';
import './index.css';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import GoogleaAddressSearch from '../GoogleAutoComplete';
import { createUsers, getUser, showLoader, hideLoader } from '../../../actions';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { calculateBounds } from 'tsparticles/Utils';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const defaultValues = {
  loanNumber: 1048,
  loanType: 'Home equity line of credit',
  applicationStep: '/selectOffer/',
  propertyAddress: '123 Elm Street',
  apartmentNumber: '836',
  city: 'Lewisburg',
  state: 'OH',
  zipCode: '12412',
  primaryResidence: 'home',
  financingPurpose: 'Debt Consolidation',
  firstName: 'Mary',
  lastName: 'Johnson',
  suffix: 0,
  dateOfBirth: 1234,
  annualIncome: '0',
  otherIncome: '0',
  phoneNumber: '124-345-9845',
  email: 'asdfada@gmcail.com',
  password: '1234124',
  confirmPassword: '1234124',
  term: 15,
  apr: 6.4,
};

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description,
  createUsers,
  showLoader,
  hideLoader,
  loading,
  currentUser,
  user,
}) => {
  const [form] = Form.useForm();
  const [googleAddress, setGoogleAddress] = useState(null);
  const [googleAddress2, setGoogleAddress2] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [propertyUsageType, setPropertyUsageType] = useState(false);

  const onFinish = async (values) => {
    var income = values.annualIncome;
    income = income.replace(/,/g, '');

    var updateObject = {
      borrower: {
        firstName: values.firstName,
        lastName: values.lastName,
        suffix: values.suffix,
        dateOfBirth: values.dateOfBirth,
        addressLineText: values.borrowerPropertyAddress,
        cityName: values.borrowerCityName,
        stateCode: values.borrowerStateCode,
        postalCode: values.borrowerPostalCode,
        countryCode: values.borrowerCountryCode,
        contactPointTelephoneValue: values.phoneNumber,
        income: {
          totalYearlyIncome: income,
        },
      },
      subjectProperty: {
        address: {
          addressLineText: values.propertyAddress,
          /* addressUnitIdentifier: JSON.parse(
            localStorage.getItem('addressUnitIdentifier')
          ), */
          cityName: values.city,
          stateCode: values.state,
          postalCode: values.zipCode,
        },
        propertyDetail: {
          propertyUsageType: values.propertyUsageType,
        },
      },
      miscellaneous: {
        financingPurpose: values.financingPurpose,
        //financingUse:
      },
      email: values.email,
    };

    console.log('onFinish updateObject ', updateObject);
    showLoader();
    setFormSubmitted(true);
    await createUsers(updateObject);

    hideLoader();
    console.log('user', user);
    console.log('currentUser application', currentUser);
    navigate(currentUser.borrower.helocLinkURL);
  };

  const onPrimaryResidenceChange = (e) => {
    console.log('property Usage', e);
    setPropertyUsageType(e);
  };

  const onChangeValue = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress(val);
    form.setFieldsValue({
      propertyAddress: val.street_address + ' ' + val.route,
      state: val.state,
      city: val.city,
      zipCode: val.zip_code,
    });
  };

  const onChangeValuePrimary = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress2(val);
    form.setFieldsValue({
      borrowerPropertyAddress: val.street_address + ' ' + val.route,
      borrowerState: val.state,
      borrowerCity: val.city,
      borrowerZipCode: val.zip_code,
    });
  };

  console.log('google address===>', googleAddress);

  useEffect(() => {
    hideLoader();
  }, [currentUser, propertyUsageType]); // <-- empty dependency array

  return (
    <SectionMainWrapper>
      {loading ? (
        <FullPageLoader />
      ) : formSubmitted ? (
        ''
      ) : (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}></Box>
            <Box {...row}>
              {/*  <Box {...contactForm}> */}

              <ContactFromWrapper>
                <Form
                  //{...formItemLayout}
                  form={form}
                  layout="vertical"
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                  style={{ width: '80%', fontWeight: '600' }}
                >
                  <FeatureBlock
                    title={<Heading {...title} />}
                    description={<Text {...description} />}
                  />
                  <Text content="Enter property address for financing"></Text>
                  <GoogleaAddressSearch onChangeValue={onChangeValue} />
                  <p></p>

                  {googleAddress !== null && (
                    <Form.Item
                      name="propertyAddress"
                      label="Property address"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress !== null && (
                    <Form.Item
                      name="apartmentNumber"
                      label="Apartment, Suite, Unit"
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress !== null && (
                    <Form.Item
                      name="city"
                      label="City"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress !== null && (
                    <Form.Item
                      name="state"
                      label="State abbreviation"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                        {
                          max: 2,
                          message: 'Please only use two characters',
                        },
                        {
                          min: 2,
                          message: 'Please input a valid state',
                        },
                        {
                          pattern: /[a-zA-Z]+/,
                          message: 'Letters only',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  {googleAddress !== null && (
                    <Form.Item
                      name="zipCode"
                      label="Zip Code"
                      rules={[
                        {
                          required: true,
                          message: '*Required',
                        },
                        {
                          pattern: /^\d{5}(?:[-\s]\d{4})?$/,
                          message: 'Please enter a valid zip code',
                        },
                        {
                          min: 5,
                          message: '*Must be at least 5 digits',
                        },
                      ]}
                    >
                      <Input autoComplete="new-password" />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="propertyUsageType"
                    label="Occupany Type"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      onChange={onPrimaryResidenceChange}
                      allowClear
                    >
                      <Option value="PrimaryResidence">Primary property</Option>
                      <Option value="SecondHome">Second home</Option>
                      <Option value="Investment">Investment property</Option>
                    </Select>
                  </Form.Item>

                  {/*  //primary home address display if propertyUsageType not primary property*/}
                  {(propertyUsageType === 'Investment' ||
                    propertyUsageType === 'SecondHome') && (
                    <Text content="Your primary residence address"></Text>
                  )}

                  {(propertyUsageType === 'Investment' ||
                    propertyUsageType === 'SecondHome') && (
                    <GoogleaAddressSearch
                      onChangeValue={onChangeValuePrimary}
                    />
                  )}
                  <p></p>

                  {googleAddress2 !== null &&
                    (propertyUsageType === 'Investment' ||
                      propertyUsageType === 'SecondHome') && (
                      <Form.Item
                        name="borrowerPropertyAddress"
                        label="Property address"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )}

                  {googleAddress2 !== null &&
                    (propertyUsageType === 'Investment' ||
                      propertyUsageType === 'SecondHome') && (
                      <Form.Item
                        name="borrowerApartmentNumber"
                        label="Apartment, Suite, Unit"
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )}

                  {googleAddress2 !== null &&
                    (propertyUsageType === 'Investment' ||
                      propertyUsageType === 'SecondHome') && (
                      <Form.Item
                        name="borrowerCity"
                        label="City"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )}

                  {googleAddress2 !== null &&
                    (propertyUsageType === 'Investment' ||
                      propertyUsageType === 'SecondHome') && (
                      <Form.Item
                        name="borrowerState"
                        label="State abbreviation"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                          {
                            max: 2,
                            message: 'Please only use two characters',
                          },
                          {
                            min: 2,
                            message: 'Please input a valid state',
                          },
                          {
                            pattern: /[a-zA-Z]+/,
                            message: 'Letters only',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )}

                  {googleAddress2 !== null &&
                    (propertyUsageType === 'Investment' ||
                      propertyUsageType === 'SecondHome') && (
                      <Form.Item
                        name="borrowerZipCode"
                        label="Zip Code"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                          {
                            pattern: /^\d{5}(?:[-\s]\d{4})?$/,
                            message: 'Please enter a valid zip code',
                          },
                          {
                            min: 5,
                            message: '*Must be at least 5 digits',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )}

                  <Form.Item
                    name="borrowerFinancingPurpose"
                    label="Financing purpose"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      //onChange={onPrimaryResidenceChange}
                      allowClear
                    >
                      <Option value="DEBT CONSOLIDATION">
                        Debt consolidation
                      </Option>
                      <Option value="HOME IMPROVEMENT">Home improvement</Option>
                      <Option value="MAJOR PURCHASE">Major purchase</Option>
                      <Option value="OTHER">Other</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="firstName"
                    label="Legal first name"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="lastName"
                    label="Legal last name"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item name="suffix" label="Suffix">
                    <Select placeholder="Select" allowClear>
                      <Option value="JR">Jr</Option>
                      <Option value="SR">Sr</Option>
                      <Option value="I">I</Option>
                      <Option value="II">II</Option>
                      <Option value="III">III</Option>
                      <Option value="IV">IV</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="dateOfBirth"
                    label="Date of birth"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="01/01/1968"
                      options={{ date: true, datePattern: ['m', 'd', 'Y'] }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="annualIncome"
                    label="Annual gross household salary income including bonus"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="$"
                      options={{
                        numeral: true,
                        numeralIntegerScale: 7,
                        numeralPositiveOnly: true,
                        //prefix: '$',
                        signBeforePrefix: true,
                        stripLeadingZeroes: true,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="otherIncome"
                    label="Other income (rental, investment, retirement, etc.)"
                    rules={[
                      {
                        required: false,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="$"
                      options={{
                        numeral: true,
                        numeralIntegerScale: 7,
                        numeralPositiveOnly: true,
                        prefix: '$',
                        signBeforePrefix: true,
                        stripLeadingZeroes: true,
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="phoneNumber"
                    label="Phone number"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                      {
                        min: 12,
                        message: '*Must be 10 digits',
                      },
                    ]}
                  >
                    <Cleave
                      className="ant-input"
                      placeholder="888 888 8888"
                      options={{ phone: true, phoneRegionCode: 'US' }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: 'email',
                        message: 'Please enter a valid E-mail',
                      },
                      {
                        required: true,
                        message: 'Please input your E-mail',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Create password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password',
                      },
                      {
                        min: 9,
                        message: 'Must be at least 9 characters',
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Confirm Password"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      {
                        min: 9,
                        message: 'Must be at least 9 characters',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            'The two passwords that you entered do not match'
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject('Should accept agreement'),
                      },
                    ]}
                    {...tailFormItemLayout}
                  >
                    <Checkbox>
                      I agree to the{' '}
                      <a href="">terms of service and privacy policy</a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //color="#131176"
                      //onClick={blank()}
                      {...button}
                    >
                      See your offer
                    </Button>
                  </Form.Item>
                </Form>
              </ContactFromWrapper>
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
      )}
    </SectionMainWrapper>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    //pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['20px', '20px', '20px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    fontColor: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
    marginRight: '8px',
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Welcome to the world’s fastest Home Equity Line of Credit!',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },

  description: {
    content:
      "This process takes as few as five minutes! And checking your rate won't affect your credit.",
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
  },
};

const mapStateToProps = ({ root: { currentUser, showSidebar } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
  showSidebar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
    getUser: (userId) => dispatch(getUser(userId)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    //toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

const ContactSectionRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactSection);

export default ContactSectionRedux;
